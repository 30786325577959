import Phaser from 'phaser'
import Preloader from './scenes/Preloader';
import Game from './scenes/Game';

const config: Phaser.Types.Core.GameConfig = {
	"title": 'Sokoban Game',
	"width": 1280,
	"height": 1024,
	"type": Phaser.AUTO,
	"backgroundColor": '#000',
	"parent": 'game-container',
  "scene" : [Preloader, Game],
  "physics": {
    default: 'arcade',
    arcade: {
      gravity: { y: 0 },
    }
  },
	"scale": {
		"mode": Phaser.Scale.FIT,
		"autoCenter": Phaser.Scale.CENTER_HORIZONTALLY
	},
}

export default new Phaser.Game(config);
