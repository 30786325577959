export enum Direction {
  Up = 'up',
  Down = 'down',
  Left = 'left',
  Right = 'right',
}

export enum TileReference {
  OrangeBox = 6,
  RedBox = 7,
  BlueBox = 8,
  GreenBox = 9,
  GreyBox = 10,
  OrangeTarget = 25,
  RedTarget = 38,
  BlueTarget = 51,
  GreenTarget = 64,
  GreyTarget = 77,
}
