export const loadPlayerAnims = (scene: Phaser.Scene) => {
  scene.anims.create({
    key: 'player-idle-down',
    frames: [{ key: 'tiles', frame: 52 }],
  });

  scene.anims.create({
    key: 'player-walk-down',
    frames: scene.anims.generateFrameNumbers('tiles', { frames: [53, 52, 54, 52] }),
    frameRate: 10,
    repeat: -1
  });

  scene.anims.create({
    key: 'player-idle-up',
    frames: scene.anims.generateFrameNumbers('tiles', { start: 55, end: 55 }),
  });

  scene.anims.create({
    key: 'player-walk-up',
    frames: scene.anims.generateFrameNumbers('tiles', { frames: [56, 55, 57, 55] }),
    frameRate: 10,
    repeat: -1
  });

  scene.anims.create({
    key: 'player-idle-right',
    frames: scene.anims.generateFrameNumbers('tiles', { start: 78, end: 78 }),
  });

  scene.anims.create({
    key: 'player-walk-right',
    frames: scene.anims.generateFrameNumbers('tiles', { frames: [79, 78, 80, 78] }),
    frameRate: 10,
    repeat: -1
  });

  scene.anims.create({
    key: 'player-idle-left',
    frames: scene.anims.generateFrameNumbers('tiles', { start: 81, end: 81 }),
  });

  scene.anims.create({
    key: 'player-walk-left',
    frames: scene.anims.generateFrameNumbers('tiles', { frames: [82, 81, 83, 81] }),
    frameRate: 10,
    repeat: -1
  });
}

export const initAnims = (scene: Phaser.Scene) => {
  loadPlayerAnims(scene);
}
