export default class Preloader extends Phaser.Scene {
  constructor() {
    super('preloader');
  }

  preload = () => {
    // Tileset
    this.load.spritesheet('tiles', 'assets/sokoban_tilesheet@2.png', {
      frameWidth: 128,
      startFrame: 0
    });
  }

  create = () => {
    this.scene.start('game');
  }
}
