import Phaser from 'phaser'
import { initAnims } from '~/utils/animLoader';
import { Direction, TileReference } from '~/utils/enums';

export default class Game extends Phaser.Scene {
  private player!: Phaser.GameObjects.Sprite;
  private layer!: Phaser.Tilemaps.TilemapLayer;
  private cursors!: Phaser.Types.Input.Keyboard.CursorKeys;
  private targetsCovered: { [key: number]: number } = {};
  private targetTotals: { [key: number]: number } = {};
  private boxList: { [key: number]: Phaser.GameObjects.Sprite[] } = {};
  private playerMoving = false;
  private obstacles = [99, 100];
  private hasWon = false;

  constructor() {
    super('game')
  }

  preload = (): void => {
    this.cursors = this.input.keyboard.createCursorKeys();
    initAnims(this);
  }

  create = (): void => {
    const level = [
      [99, 99, 99, 99, 99, 99, 99, 99, 99, 99],
      [99, 0, 0, 0, 0, 77, 0, 0, 0, 99],
      [99, 0, 25, 8, 0, 0, 10, 0, 0, 99],
      [99, 0, 0, 64, 9, 0, 0, 51, 0, 99],
      [99, 0, 0, 0, 0, 0, 0, 0, 0, 99],
      [99, 0, 38, 7, 52, 0, 0, 6, 0, 99],
      [99, 0, 0, 0, 0, 0, 0, 0, 0, 99],
      [99, 99, 99, 99, 99, 99, 99, 99, 99, 99],
    ];

    const map = this.make.tilemap({ data: level, tileWidth: 128, tileHeight: 128 });

    const tiles = map.addTilesetImage('tiles');
    this.layer = map.createLayer(0, tiles, 0, 0);

    // Player
    this.player = this.layer.createFromTiles(52, 0, { key: 'tiles', frame: 52 }, this).pop() || this.add.sprite(128, 128, 'tiles', 52);
    this.player.setOrigin(0);
    this.player.anims.play('player-idle-down');

    // Moveable Boxes
    this.extractBoxes();
  }

  update = (): void => {
    if (!this.cursors || !this.player || this.playerMoving || this.hasWon) return;

    if (this.cursors.up.isDown) {
      this.movePlayer(Direction.Up, -128);
    }
    else if (this.cursors.down.isDown) {
      this.movePlayer(Direction.Down, 128);
    }
    else if (this.cursors.left.isDown) {
      this.movePlayer(Direction.Left, -128);
    }
    else if (this.cursors.right.isDown) {
      this.movePlayer(Direction.Right, 128);
    }
  }

  private extractBoxes = (): void => {
    const boxTypes = [TileReference.OrangeBox, TileReference.RedBox, TileReference.BlueBox, TileReference.GreenBox, TileReference.GreyBox];
    const targetTypes = [TileReference.OrangeTarget, TileReference.RedTarget, TileReference.BlueTarget, TileReference.GreenTarget, TileReference.GreyTarget];
    boxTypes.map(boxID => {
      this.boxList[boxID] = this.layer.createFromTiles(boxID, 0, { key: 'tiles', frame: boxID }, this);
      this.boxList[boxID].map(box => box.setOrigin(0));
      const targetID = this.getTargetID(boxID);
      if (targetID) this.targetsCovered[targetID] = 0;
      if (targetID) this.targetTotals[targetID] = 0;
    });

    this.layer.forEachTile((tile: Phaser.Tilemaps.Tile): void => {
      if (targetTypes.includes(tile.index)) this.targetTotals[tile.index] += 1;
    });
  }

  private getCheckCoords = (direction: Direction, x: number, y: number): { checkX: number, checkY: number } => {
    let checkX: number = x, checkY: number = y;
    switch (direction) {
      case Direction.Up:
        checkX = x + 64;
        checkY = y - 64;
        break;
      case Direction.Down:
        checkX = x + 64;
        checkY = y + 192;
        break;
      case Direction.Left:
        checkX = x - 64;
        checkY = y + 64;
        break;
      case Direction.Right:
        checkX = x + 192;
        checkY = y + 64;
        break;
    }
    return { checkX, checkY };
  }

  private getBoxAt = (direction: Direction, x: number, y: number): ({ box: Phaser.GameObjects.Sprite, boxID: number } | undefined) => {
    let { checkX, checkY } = this.getCheckCoords(direction, x, y);
    for (let key of Object.keys(this.boxList)) {
      const boxes = this.boxList[key];
      const box = boxes.find((box: Phaser.GameObjects.Sprite) => {
        const rect = box.getBounds();
        return rect.contains(checkX, checkY);
      });
      if (box) return { box, boxID: parseInt(key) };
    }
  }

  private hasWallAt = (direction: Direction, x: number, y: number): boolean => {
    if (!this.layer) return false;
    let { checkX, checkY } = this.getCheckCoords(direction, x, y);

    const tile = this.layer.getTileAtWorldXY(checkX, checkY);
    return this.obstacles.includes(tile.index);
  }

  private stopPlayerAnimation = (): void => {
    const [, , key] = this.player.anims.currentAnim.key.split('-');
    this.player.anims.play(`player-idle-${key}`, true);
    this.playerMoving = false;
  }

  private hasTargetAt = (tileIndex: number, box: Phaser.GameObjects.Sprite): boolean => {
    if (!this.layer) return false;
    const tile = this.layer.getTileAtWorldXY(box.x, box.y);
    if (!tile) return false;
    return tile.index === tileIndex;
  }

  private changeTargetCoveredCount(colour: number, change: number): void {
    if (!(colour in this.targetsCovered)) {
      this.targetsCovered[colour] = 0;
    }
    this.targetsCovered[colour] += change;
  }

  private getTargetID = (boxID: TileReference): TileReference | undefined => {
    switch (boxID) {
      case TileReference.OrangeBox:
        return TileReference.OrangeTarget;
      case TileReference.RedBox:
        return TileReference.RedTarget;
      case TileReference.BlueBox:
        return TileReference.BlueTarget;
      case TileReference.GreenBox:
        return TileReference.GreenTarget;
      case TileReference.GreyBox:
        return TileReference.GreyTarget;
    }
  }

  private checkWinState = (): void => {
    let winFlag = true;
    Object.keys(this.targetsCovered).forEach((targetID: string) => {
      console.log(this.targetsCovered[targetID], this.targetTotals[targetID]);
      if (this.targetsCovered[targetID] !== this.targetTotals[targetID]) winFlag = false;
    });
    if (winFlag) {
      this.hasWon = true;
      alert('You win!');
    }
  }

  private movePlayer(direction: Direction, moveFactor: number): void {
    if (this.hasWallAt(direction, this.player.x, this.player.y)) {
      this.player.anims.play(`player-idle-${direction}`, true);
      return;
    }

    this.playerMoving = true;
    this.player.anims.play(`player-walk-${direction}`, true);
    const boxMatch = this.getBoxAt(direction, this.player.x, this.player.y);
    const tweenBase = direction === Direction.Up || direction === Direction.Down ? { duration: 300, y: `+= ${moveFactor}` } : { duration: 300, x: `+= ${moveFactor}` };

    if (boxMatch) {
      const { box, boxID } = boxMatch;
      const targetID = this.getTargetID(boxID);
      if (this.hasWallAt(direction, box.x, box.y) || this.getBoxAt(direction, box.x, box.y)) {
        this.player.anims.play(`player-idle-${direction}`, true);
        this.playerMoving = false;
        return;
      }
      if (targetID) {
        const targetHit = this.hasTargetAt(targetID, box);
        if (targetHit) this.changeTargetCoveredCount(targetID, -1);
      }
      this.tweens.add({
        ...tweenBase,
        targets: box,
        onComplete: () => {
          if (targetID) {
            const targetHit = this.hasTargetAt(targetID, box);
            if (targetHit) this.changeTargetCoveredCount(targetID, 1);
            this.checkWinState();
          }
        },
      });
    }

    this.tweens.add({
      ...tweenBase,
      targets: this.player,
      onComplete: this.stopPlayerAnimation,
      onCompleteScope: this,
    });
  }
}
